<template>
    <div class="modal">
        <div class="modal__container">
            <h1>Usuário bloqueado.</h1>
            <div>
                <div class="modal__mensagem">
                    <p>
                        Entre em contato com o <strong>0800 236 0011</strong> e fale com um de nossos atendentes.
                    </p>
                </div>
            </div>

            <div class="modal__btn box-botoes">
                <button class="modal__btn__btn-enviar" @click="fechaModal">
                    Fechar
                </button>
            </div>
        </div>
    </div>
</template>
    
<script>
import iconesImgs from "@/utils/icones.js";
export default {

    data() {
        return {
            iconesImgs,
        };
    },

    methods: {
        fechaModal() {
            this.$store.commit("user/SET_LOGADO", false);
            this.$store.commit("user/SET_PERFIL", "");
            this.$store.commit("user/SET_ID_USUARIO", "");
            this.$store.commit("usuario/SET_STATUS_USUARIO", false);
            this.$router.push("/login");
        }
    },
};
</script>
  
<style lang="scss" scoped>
.box-botoes {
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 10px;
}

.modal__container {
    padding: 50px 50px;
    min-height: 300px;
    background: $cor-gelo;

    h1 {
        padding: 30px 0;
        font-size: 26px;
    }

    p {
        font-size: 20px;
        max-width: 450px;
        width: 100%;
    }

    @media(max-width: 768px) {
        padding: 40px 20px;
        height: auto;
        max-height: 100%;
        overflow-y: visible;
    }


}
</style>